/**************** 레이아웃 ****************/
#admin-layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#default-layout .ant-layout-header {
  display: flex;
  height: 42px;
  line-height: 42px;
  justify-content: space-between;
}

#default-layout .logo {
  color: #1890ff;
  margin-right: 30px;
}

#default-layout .account-action {
  /* float: right; */
  /* margin: 16px 24px 16px 0; */
  color: white;
}

.site-layout .site-layout-background {
  background: #fff;
}

.helpPhone {
  margin-right: 30px;
  color: lightblue;
  font-weight: bold;
}

/**************** 테이블 ****************/
.agenciesTable .ant-table-cell {
  padding: 12px;
}
/* 에러메시지? */
.ant-form-item-explain {
  font-size: 13px;
}

/**************** 등록현황 ****************/
#stats_calendar .ant-picker-calendar-date-value {
  font-size: 16px;
  text-align: center;
}

#stats_calendar .ant-picker-content th {
  text-align: center;
}

#stats_calendar .ant-picker-cell {
  cursor: default;
}

/**************** 공지사항 ****************/
.ql-editor {
  min-height: 300px;
  font-size: 16px;
}

.ql-editor p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.6;
}

.ql-editor img {
  max-width: 100%;
}

/**************** 대리점 관리 - 검색 ****************/
.active-agency {
  background-color: #b7eb8f;
}
